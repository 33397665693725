<template>
  <div>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_1770_1022" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask0_1770_1022)">
        <path
            d="M20.0003 20.6666C20.0003 21.3992 19.4059 22 18.6699 22H5.33031C4.59743 22 4 21.4014 4 20.6666C4 16.2485 7.58165 12.6666 12 12.6666C16.4183 12.6666 19.9999 16.2486 19.9999 20.6666H20.0003ZM12.0003 14.4446C8.71316 14.4446 6.02158 16.9932 5.79372 20.2224H18.2072C17.9793 16.9932 15.2877 14.4446 12.0006 14.4446H12.0003ZM12.0003 11.7777C9.3003 11.7777 7.11126 9.58902 7.11126 6.88903C7.11126 4.1888 9.3003 2 12.0003 2C14.7003 2 16.8893 4.18872 16.8893 6.88903C16.8893 9.58902 14.7003 11.7777 12.0003 11.7777ZM12.0003 10.0001C13.7184 10.0001 15.1113 8.6072 15.1113 6.88911C15.1113 5.1707 13.7184 3.77777 12.0003 3.77777C10.2822 3.77777 8.88927 5.1707 8.88927 6.88911C8.88927 8.6072 10.2822 10.0001 12.0003 10.0001Z"
            fill="#1E70BB"/>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconAccount"
}
</script>

<style scoped>

</style>